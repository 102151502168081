const PRODUCT_LIST_API_URL_MAP = {
  'product-list-v2': '/api/productList/info/get',
  'shein-picks': '/api/productList/picksInfo/get',
  'picked-info-list': '/api/recProductList/recInfo/get',
  'feedback-rec-list': '/api/recProductList/recInfo/get',
  'discount-channel-list': '/api/discountChannelList/disInfo/get',
}

// 属于泛列表的路由名字: 一般列表, 信息流列表 (包括大卡片), 实时反馈列表
const PRODUCT_LIST_ROUTE_NAME = [
  'product-list-v2',
  'picked-info-list',
  'feedback-rec-list',
  'discount-channel-list',
  'shein-picks',
]

const NOT_CACHE_KEYS = ['scici', 'src_module', 'src_identifier', 'src_tab_page_id', 'new_src_tab_page_id', 'src_identifier_pre_search']

export {
  PRODUCT_LIST_ROUTE_NAME,
  PRODUCT_LIST_API_URL_MAP,
  NOT_CACHE_KEYS
}
