import schttp from 'public/src/services/schttp'
import { ApiCache } from '../ApiCache/index'
import { defaultRequestInfo, getCurrentToRoute, setCurrentToRoute, getPdeParams, PointerOverGoodsRequest, generateRouterParams } from './utils'
import { PRODUCT_LIST_API_URL_MAP, PRODUCT_LIST_ROUTE_NAME, NOT_CACHE_KEYS } from './constant.js'
import { preloadImg } from '../../utlis/prefetch'
import generateParams from './generateParams'
import { markPoint } from 'public/src/services/mark/index.js'

let abortionInstance = null

const apiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (params) => {
    if (abortionInstance) {
      abortionInstance.abort()
    }
    abortionInstance = new SchttpAbortCon()
    const route = getCurrentToRoute()
    const url = PRODUCT_LIST_API_URL_MAP[route.name]
    if (!url) {
      throw new Error('未找到对应的 url')
    }
  
    const res = await schttp({
      url,
      params,
      headers: getPdeParams(),
      signal: abortionInstance.signal,
      isPreLoad: true,
    })
  
    const MARK_NAME = 'pre_request_product_list_img'
    markPoint({ eventName: MARK_NAME, tag: 'begin' })
    function handlePreloadImgs() {
      // 对 goods 中的前 4 张商品主图 (包括 spu 图) 进行预取
      const prefetchGoods = res?.goods?.slice(0, 4) || []
      const mainImages = prefetchGoods.map(item => item.goods_img)
      const spuImages = prefetchGoods.map(item => item.spu_image?.[0]?.medium_image).filter(img => img && img !== 'filteredSpuImg')
      // 以及对图文导航前 5 张图片进行预取
      const picTopNavImages = res?.picTopNav?.[0]?.navs?.slice(0, 5).map(item => item.nav_img).filter(img => img) || []
      // 取搜索店铺卡片图片
      const searchStoreCardImgs = res?.searchBannerCard?.searchStoreCard?.products?.map(item => item.goods_img).filter(v => v) || []
      // 取搜索趋势卡片店铺图片
      const trendCargBg = 'https://img.ltwebstatic.com/images3_ccc/2024/05/21/45/17162772482320df6564cdf819da0dcf50402ffdba.png'
      const trendCardProductImg = res.searchBannerCard?.searchTrendCard?.mianImg // 商品图
      const trendCardLineChat = res.searchBannerCard?.searchTrendCard?.growthLabel?.growthImage // 折线图
      const trendCardImgs = [trendCardProductImg, trendCardLineChat]
      if (trendCardImgs.length) {
        trendCardImgs.push(trendCargBg)
      }
      return Promise.all([
        // 338 这个图片设计尺寸来自于列表双列布局下, 商品主图的设计稿尺寸, 参考 Item.vue 中的变量: columnDesignWidthMap
        preloadImg({ imgs: [...mainImages, ...spuImages], designWidth: 338 }), 
        // 这里设计尺寸传 0, 是因为不用裁切, 只是做 webp 的替换
        preloadImg({ imgs: [...picTopNavImages, ...searchStoreCardImgs, ...trendCardImgs], designWidth: 0 })
      ])
    }
    handlePreloadImgs().then(() => markPoint({ eventName: MARK_NAME, tag: 'end' }))
    return res
  },
  // 配置过滤掉不参加缓存的参数key
  notCacheKeys: NOT_CACHE_KEYS,
})

/**
 * 获取商品列表数据的接口调用
 * */ 
const requestProductListApi = async (requestInfo, newSrcTabPageId) => {
  if (typeof window === 'undefined') return
  const params = generateParams(requestInfo, newSrcTabPageId)
  try {
    const result = await apiCache.request(params)
    return result
  } catch (e) {
    // console.error(e)
    return {}
  }
}

/**
 * 检测是否数据已经缓存
*/
const getProductListApiCache = (requestInfo, newSrcTabPageId) => {
  const params = generateParams(requestInfo, newSrcTabPageId)
  return apiCache.getCacheData(params)
}

let prehtmlFlag = false // 单页水合时，不发起预请求
function changePreHtmlUpdate(value) {
  prehtmlFlag = value
}

/**
 * 路由的预请求
 * */
function handleProductListPreRequestBeforeEachRoute(to, from) {
  if (prehtmlFlag) return

  const isProductListRoute = PRODUCT_LIST_ROUTE_NAME.includes(to.name)
  if (!isProductListRoute) return
  setCurrentToRoute(to)
  const isSpaRouteChange = (fromRoute) => Boolean(fromRoute.name)
  const isSameRouteChange = (to, from) => to.name === from.name
  
  if (isSpaRouteChange(from)) {
    if (!isSameRouteChange(to, from)) {
      requestProductListApi(defaultRequestInfo(to.query))
    }
  } else {
    // 说明是 ssr 渲染了列表页, 不需要接口预请求
  }
}

if (typeof window !== 'undefined') {
  window.apiCacheProducts = apiCache
}

/**
 * 触摸提前预请求方法。让请求提前触发
 * */
const pointerOverGoodsRequest = new PointerOverGoodsRequest({
  fetch: (path) => {
    const toRouteParams = generateRouterParams(path)
    if (!toRouteParams) return
    handleProductListPreRequestBeforeEachRoute(toRouteParams, { name: 'preload-request' })
  },
  cancelToken: () => abortionInstance?.abort?.(),
})

export {
  abortionInstance,
  requestProductListApi,
  setCurrentToRoute,
  handleProductListPreRequestBeforeEachRoute,
  pointerOverGoodsRequest,
  changePreHtmlUpdate,
  getProductListApiCache,
}
